.blog-grid {
    cursor: pointer;
    .blog-img {
        overflow: hidden;
        img {
            @include transition(ease all 0.35s);
            @include scale(1);
        }
    }
    .blog-info {
        padding-top: 15px;
        .meta {
            font-size: 12px;
        }
        h6 {
            font-size: 20px;
            font-weight: 500;
            padding-top: 10px;
            line-height: 1.3;
            @include down-md {
                font-size: 20px;
            }
            @include down-sm {
                font-size: 20px;
            }
        }
        a {
            color: $px-white;
            background-image: linear-gradient(130deg, $px-white, $px-white);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            @include transition(ease all 0.35s);
            .theme-light & {
                color: $px-dark;
                background-image: linear-gradient(130deg, $px-dark, $px-dark);
            }
        }
    }
    &:hover {
        .blog-info {
            a {
                background-size: 100% 2px;
            }
        }
        .blog-img {
            img {
                // filter: grayscale(1);
                // -webkit-filter: grayscale(1);
                // @include scale(1.1);
            }
        }
    }
}

.read-more-blog {
    padding-top: 30px;
}

.blog-pagination {
    padding-top: 30px;
}

.text-white {
    color: #fff;
}

.pagination {
    .page-item {
        .page-link {
            border: none;
            box-shadow: none;
            color: $px-white;
            margin: 0 5px;
            border-radius: 50px;
            padding: 0;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            background: rgba($px-white, 0.1);
            .theme-light & {
                background: rgba($px-dark, 0.2);
                color: $px-dark;
                font-weight: 500;
            }
            &:hover {
                background: $px-theme;
                border-color: $px-theme;
                color: $px-white;
            }
        }
        &.disabled {
            .page-link {
                opacity: 0.5;
            }
        }
        &.active {
            .page-link {
                background: $px-theme;
                border-color: $px-theme;
                color: $px-white;
            }
        }
    }
}

.tag-cloud {
    li {
        padding: 4px 15px;
        font-size: 13px;
        color: #0b0b13;
        background: #f7f7ff;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-weight: 400;
        .theme-light & {
            color: $px-dark;
            background: $px-gray;
        }
        &:hover {
            background: $px-theme;
            color: $px-white;
        }
    }
}

.blog-listing {
    padding-top: 120px;
    padding-bottom: 120px;
    @include down-md {
        padding-bottom: 80px;
    }
    @include down-sm {
        padding-bottom: 50px;
    }
}

.single-blog {
    padding-top: 120px;
    padding-bottom: 120px;
    @include down-md {
        padding-top: 100px;
        padding-bottom: 80px;
    }
}

.article {
    margin: 15px 0 30px;
    .article-title {
        padding: 15px 0 20px;
        h6 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
            a {
                text-transform: uppercase;
                color: $px-theme;
                border-bottom: 1px solid $px-theme;
            }
        }
        h2 {
            color: $px-white;
            font-weight: 600;
            .theme-light & {
                color: $px-dark;
            }
            @include down-md {
                font-size: 28px;
            }
        }
        .media {
            padding-top: 15px;
            border-bottom: 1px dashed rgba($px-white, 0.1);
            padding-bottom: 20px;
            .theme-light & {
                border-bottom: 1px dashed rgba($px-dark, 0.1);
            }
            .avatar {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                overflow: hidden;
            }
            .media-body {
                padding-left: 8px;
                label {
                    font-weight: 600;
                    color: $px-theme;
                    margin: 0;
                }
                span {
                    display: block;
                    font-size: 12px;
                    color: #fff;
                }
            }
        }
    }

    .article-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 500;
            margin-bottom: 15px;
            margin-top: 30px;
            .theme-light & {
                color: $px-dark;
            }
        }
        blockquote {
            max-width: 600px;
            padding: 15px 0 30px 0;
            margin: 0;
            p {
                font-size: 20px;
                font-weight: 500;
                color: $px-theme;
                margin: 0;
            }
            .blockquote-footer {
                color: $px-dark;
                font-size: 16px;
                margin-top: 5px;
                .theme-light & {
                    color: $px-dark;
                }
                cite {
                    font-weight: 600;
                }
            }
        }
    }
    .tag-cloud {
        padding-top: 10px;
    }
}
.article-comment {
    background: #f7f7ff;
}
.article-comment {
    border-radius: 5px;
    overflow: hidden;
    padding: 30px;
    .theme-light & {
        background: $px-gray;
    }
    h4 {
        font-weight: 700;
        margin-bottom: 25px;
        font-size: 22px;
        .theme-light & {
            color: $px-dark;
        }
    }
}
