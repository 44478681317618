.loader-area {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #010101;
}

.cssload-loader {
  width: 244px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.2em;
}
.cssload-loader::before,
.cssload-loader::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  animation: cssload-load 0.81s infinite alternate ease-in-out;
  -o-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
}
.cssload-loader::before {
  top: 0;
}
.cssload-loader::after {
  bottom: 0;
}

@keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-o-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-ms-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-webkit-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-moz-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

.ReactModal__Body--open {
  .ReactModal__Content--after-open {
    .box_inner {
      .article .article-title h2 {
        color: #0b0b13;
      }
      .article .article-content h1,
      .article .article-content h2,
      .article .article-content h3,
      .article .article-content h4,
      .article .article-content h5,
      .article .article-content h6 {
        color: #0b0b13;
      }
      .px-btn.px-btn-white {
        background: #0b0b13;
        border-color: #0b0b13;
        color: #fff;
        &:hover {
          background: transparent;
          color: #0b0b13;
        }
      }
    }
  }
}
.counter-fonts {
  font-size: 38px;
}
.theme-dark {
  .resume-row .rb-left label {
    color: #fff;
  }
  .header-left {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    .nav-menu li + li {
      border-top-color: rgba(255, 255, 255, 0.1);
    }
  }
  p,
  span,
  .meta {
    color: rgba(255, 255, 255, 0.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .about-info .info-list label {
    color: $px-white !important;
  }
  .feature-box-01,
  .feature-box-02,
  .testimonial-01 {
    background: #010101;
  }
  .separated {
    filter: invert(1);
  }
  .home-banner {
    .type-box {
      .desc {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }
      .lead {
        color: $px-theme;
      }
      h6 {
        color: #c8c8cb;
      }
    }
  }
  .contact-form .form-control.theme-light {
    color: rgba(255, 255, 255, 0.73);
    border-color: rgba(255, 255, 255, 0.3);
    background: #0e0e17;
  }
  .px-btn.px-btn-white {
    background: #fff;
    border-color: #fff;
    color: #0b0b13;
    &:hover {
      background: transparent;
      color: #fff;
    }
  }
  .portfolio-filter-01 .filter li.react-tabs__tab {
    color: #fff;
  }
}
