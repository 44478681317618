/*---------------------------
 Portfolio Start
------------------------------*/
.portfolio-content {
    &.grid-col-2 {
        .grid-item {
            width: 50%;

            @include down-sm {
                width: 100%;
            }
        }
    }

    &.grid-col-3 {
        .grid-item {
            @include up-md {
                width: 33.3333%;
            }

            @include down-md {
                width: 50%;
            }

            @include down-sm {
                width: 100%;
            }
        }
    }

    &.grid-col-4 {
        .grid-item {
            @include up-md {
                width: 33.3333%;
            }

            @include up-lg {
                width: 25%;
            }

            @include down-md {
                width: 50%;
            }

            @include down-sm {
                width: 100%;
            }
        }
    }

    &.grid-col-5 {
        .grid-item {
            @include up-md {
                width: 33.3333%;
            }

            @include up-lg {
                width: 20%;
            }

            @include down-md {
                width: 50%;
            }

            @include down-sm {
                width: 100%;
            }
        }
    }

    &.grid-gutter-md {
        margin-left: -4px;
        margin-right: -4px;

        .grid-item {
            padding: 8px;
        }
    }

    &.grid-gutter-lg {
        margin-left: -12px;
        margin-right: -12px;

        .grid-item {
            padding: 12px;
        }
    }

    .grid-item {
        float: left;
    }
}

/*Portfolio Filter*/
.portfolio-filter-01 {
    .filter {
        li {
            cursor: pointer;
            margin: 0 15px;
            color: $px-white;
            position: relative;
            padding: 5px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            .theme-light & {
                color: $px-dark;
            }
            @include down-sm {
                margin: 0 8px;
                font-size: 15px;
            }
            &:after {
                content: '';
                width: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                height: 2px;
                background: $px-theme;
                position: absolute;
                @include transition(ease all 0.55s);
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }

            &.active {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

/*Portfolio Style 2*/
.portfolio-box-01 {
    background: $px-white;
    position: relative;

    .portfolio-img {
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
    img {
        width: 100%;
        @include transition(all 0.55s);
    }
    .gallery-link {
        display: block;
        position: relative;
        cursor: zoom-in !important;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.65);
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            @include transition(all 0.55s);
        }
    }
    .portfolio-icon {
        position: absolute;
        bottom: -20px;
        right: 20px;
        transition: all 0.55s;
        color: #fff;
        font-size: 23px;
        line-height: 1;
        background: #fff;
        color: #000;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include transition(ease all 0.35s);
        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: $px-white;
            display: inline-block;
            vertical-align: top;
            background: $px-dark;
            font-size: 24px;
            text-align: center;
            span {
                line-height: inherit;
            }
        }
    }

    .portfolio-info {
        position: absolute;
        top: 40px;
        left: 20px;
        // padding: 20px;
        z-index: 1;
        right: 20px;
        opacity: 0;
        visibility: hidden;
        @media screen and (max-width: 991px) {
            padding: 0;
        }
        @include transition(ease all 0.35s);
        h5 {
            margin: 0 0 4px;
            color: $px-white;
            font-weight: 500;
            font-size: 22px;
            @media screen and (max-width: 991px) {
                font-size: 18px;
                line-height: 29px;
            }
            &:hover {
                letter-spacing: 0.5px;
            }
            a {
                color: $px-white;
            }
        }
        span {
            color: rgba($px-white, 0.8);
        }
    }

    .img-360-icon {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 500;
        width: 40px;
    }

    &:hover {
        .portfolio-info {
            opacity: 1;
            visibility: visible;
            top: 20px;
        }
        .portfolio-icon {
            opacity: 1;
            visibility: visible;
            bottom: 20px;
        }
        .gallery-link {
            &::after {
                visibility: visible;
                opacity: 1;
            }
            img {
                transform: scale(1.2);
            }
        }
    }
}
// portfolio tab
.portfolio-filter-01 {
    padding-top: 15px;
    .filter {
        margin-bottom: 10px;
        padding-left: 0;
        li.react-tabs__tab {
            list-style-type: none;
            cursor: pointer;
            margin-left: 0;
            margin-right: 30px;
            color: $px-dark;
            position: relative;
            padding: 5px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            @include down-sm {
                margin-right: 20px;
                font-size: 15px;
                margin-bottom: 6px;
            }
            &:after {
                content: '';
                width: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                height: 2px;
                background: $px-theme;
                position: absolute;
                @include transition(ease all 0.55s);
            }

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }

            &.react-tabs__tab--selected {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}
